import apiConfig from '@/api/apiConfig'

import axios from '@axios'
import Vue from 'vue'
// import Toasted from 'vue-toasted'

// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

class BackendApiService {
    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    constructor() {
      this.axios = axios
      this.backendConfig = { ...apiConfig }
      // Request Interceptor
      this.axios.interceptors.request.use(
        config => {
          // Get token from localStorage
          const accessToken = this.getToken()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.backendConfig.tokenType} ${accessToken}`
          }
          return config
        },
        error => Promise.reject(error),
      )

      // Add request/response interceptor
      this.axios.interceptors.response.use(
        response => response,
        error => {
          // const { config, response: { status } } = error
          const { response } = error

          // if (status === 401) {

          if (response && response.status === 401) {
            if (response && response.status === 401) {
              Vue.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${response.data.message}`,
                },
              })
            }
          }
          return Promise.reject(error)
        },
      )
    }

    getToken() {
      return localStorage.getItem(this.backendConfig.storageTokenKeyName)
    }

    getRefreshToken() {
      return localStorage.getItem(this.backendConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
      localStorage.setItem(this.backendConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
      localStorage.setItem(this.backendConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
      return this.axios.post(this.backendConfig.loginEndpoint, ...args)
    }

    logout() {
      return this.axios.post(this.backendConfig.logoutEndpoint)
    }

    register(...args) {
      return this.axios.post(this.backendConfig.registerEndpoint, ...args)
    }

    // eslint-disable-next-line class-methods-use-this
    refreshToken() {
      return true
    }
}

// eslint-disable-next-line import/prefer-default-export
export const useBackendAuth = new BackendApiService()
